import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col, CloseButton, Table, Button, ModalFooter} from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import api from "../../actions/riveroApi";
import CarCardCarrousel from "../layouts/CarCardCarrousel";
import GatsbyImage from "gatsby-image";
import Cotizador, {CotizadorTabla} from "./cotizador";
import FormularioVentaSalesforce from "./formularios/FormularioSalesforce";
import { TablaDetallesCotizador, ModalCotiza, ModalSeguros, ShimmerCarCarrousel } from "./miscelaneos";
import Image from "./basico/image";

import MenuAuto from "../../images/icon-filtro/filtro-auto.png";
import MenuAutoA from "../../images/icon-filtro/filtro-auto-azul.png";
import MenuPickup from "../../images/icon-filtro/filtro-pickup.png";
import MenuPickupA from "../../images/icon-filtro/filtro-pickup-azul.png";
import MenuSuvs from "../../images/icon-filtro/filtro-suv.png";
import MenuSuvsA from "../../images/icon-filtro/filtro-suv-azul.png";
import MenuDeportivos from "../../images/icon-filtro/filtro-deportivo.png";
import MenuDeportivosA from "../../images/icon-filtro/filtro-deportivo-azul.png";

const typesImages = [
    { image: MenuAuto, imageChosen: MenuAutoA },
    { image: MenuSuvs, imageChosen: MenuSuvsA },
    { image: MenuPickup, imageChosen: MenuPickupA },
    { image: MenuDeportivos, imageChosen: MenuDeportivosA },
];

const types = ["Autos", "Suvs", "PickUps y Van", "Deportivos y Eléctronicos"];

export default function Modal3Pasos ({show, handleClose, pasos, setPasos, props}){

    const [ infoCar, setInfoCar ] = useState();
    const [ seguros, setSeguros ] = useState();
    const [ cargando, setCargando ] = useState();
    const [ infoCotiza, setInfoCotiza ] = useState();
    const [ pagoMensual, setPagoMensual ] = useState();
    const [ showSeguros, setShowSeguros ] = useState(false);
    const [ showCotizador, setShowCotizador ] = useState(false);
    const [ seguroSeleccionado, setSeguroSeleccionado ] = useState();

    function onSelectedPasos(info, paso){
        setCargando(true)
        if(pasos.paso1 === "Paso1"){
            api.getCar(info.slug).then((response) => {
                setInfoCar(response)
            });
            setPasos({
                ...pasos,
                paso1: 0,
                paso2: "Paso2"
            })
        } else if (pasos.paso2 === "Paso2") {
            //console.log("Paso 2: Al pulsar Calcular", info)
            setPasos({
                ...pasos,
                paso2: 0,
                paso3: "Paso3"
            });

            let obj = {
                car_id: info.id,
                entry_percentage: info.porcentaje,
                months: info.plazo,
                warraty_id: 1,
            }
            //console.log("Paso 2: Al pulsar Calcular", obj)
            api.createQuotation(obj).then((resp) => {
                //console.log("Cotizador", resp);
                setCargando(false)
                let object = {
                    version: info.version,
                    precio: info.precio,
                    id: info.id,
                    plazo: info.plazo,
                    porcentaje: info.porcentaje,
                    enganche: info.enganche,
                    mensualidad: resp.data.payment.monthly_payment,
                }
                setInfoCotiza(object);
            }).catch((error) => console.log(error));
   
            api.getSegurosCar(info.id).then((resp) => {
                setSeguros(resp.data);
                setSeguroSeleccionado(resp.data);
            }).catch((error) => console.log(error));
        }

    }

    function calcularCotiza(data){
        console.log("Entra en calcularCotiza", data);
        setInfoCotiza(data);
        /*setCargando(true)
        let obj = {
            car_id: data.id,
            entry_percentage: data.porcentaje,
            months: data.plazo,
            warraty_id: 1,
        }

        api.createQuotation(obj).then((resp) => {
            console.log("Mensualidad", resp.data)
            setPagoMensual(resp.data.payment);
            setInfoCotiza(data);
            setCargando(false);
        }).catch((error) => console.log(error));*/
    }

    return(
        <Modal size={pasos.paso1 ? "lg" : "xl"} centered show={show} onHide={handleClose} keyboard={false} >
            <Modal.Header className="modalHeader-3pasos"> 
                <div>
                    {pasos.paso1 === "Paso1" ? "Elige que tipo de carro buscas"  : null}
                    {pasos.paso2 === "Paso2" ? "Cotiza tu carro" : null}
                    {pasos.paso3 === "Paso3" ? "Estrena tu carro nuevo" : null}
                </div>
                <div>
                <CloseButton onClick={() => handleClose()} variant="white"/>
                </div>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: '#343a40'}}>
                {pasos.paso1 === "Paso1" ? <Paso1 onSelectedPasos={onSelectedPasos}/> : null}
                {pasos.paso2 === "Paso2" ? <Paso2 infoCar={infoCar} onSelectedPasos={onSelectedPasos}/> : null}
                {pasos.paso3 === "Paso3" ? <Paso3 cargando={cargando} setCargando={setCargando} calcularCotiza={calcularCotiza} seguroSeleccionado={seguroSeleccionado} setSeguroSeleccionado={setSeguroSeleccionado} setShowCotizador={setShowCotizador} showCotizador={showCotizador} seguros={seguros} infoCar={infoCar} infoCotiza={infoCotiza} props={props} showSeguros={showSeguros} setShowSeguros={setShowSeguros} pagoMensual={pagoMensual}/> : null}
            </Modal.Body>
        </Modal>
    )

}

function Paso1({onSelectedPasos}){

    const [ cargando, setCargando ] = useState(false);
    const [ siguiente, setSiguiente ] = useState(false);
    const [ dataCarros, setDataCarros ] = useState();

    function cambioBody(e){
        setCargando(true);
        console.log("Estamos en: cambioBody", e);
        setSiguiente(true);

        let separar = e.split(" ");

        const options = {
            filterTipos: separar,
            orderBy: "precio:asc",
            ultimoModelo: true,
            perPage: 100,
            mostRecent: true,
        }

        api.getCars(options).then((e) => e.json()).then((ejson) => {
            const filterImage = ejson.data.filter((obj) => { return obj.image !== null; })
            setDataCarros(filterImage);
            setCargando(false);
        }).catch((error) => { console.error(error); });
    }

    const mappingUnidades = dataCarros?.map((obj, i) => {
        return(
            <Col key={i} className="p-2 modal-ColUnidadesTipos-3pasos">
                <CarCardCarrousel
                    image={obj.image}
                    name={obj.name}
                    title={obj.model}
                    year={obj.year}
                    brand={obj.make}
                    price={obj.price}
                    model={obj.model}
                    info={obj}
                    onSelectedPasos={onSelectedPasos}
                />
            </Col>
        )
    })

    const mappingTipos = types.map((obj, i) => {
        return(
            <Col key={i} className="p-1 modal-ColUnidadesTipos-3pasos">
                <button variant="outline-primary" onClick={() => cambioBody(obj)} className="btn boton-filtronuevo" style={{ borderColor: "gray", minHeight: "140px", width: '90%'}}>
                    <picture style={{width: "100%", height: "100%"}}>
                    <img
                        src={typesImages[i].image}
                        alt="checkbox"
                        className="w-100"
                        loading="lazy"
                        onMouseOver={(e) => (e.currentTarget.src = typesImages[i].imageChosen)}
                        onMouseOut={(e) => (e.currentTarget.src = typesImages[i].image)}
                    />
                    </picture>
                    <div className={`text-center`} style={{ lineHeight: "15px" }}>
                    <span className="text-filtro-seleccion">
                        {obj}
                    </span>
                    </div>
                </button>
            </Col>
        )
    })

    return(
        <Container>
            <Row lg={siguiente === true ? 3 : 2} className="px-4">
                {
                    siguiente === true ? (
                        cargando ?  <ShimmerCarCarrousel/> : mappingUnidades
                    ) : (
                        mappingTipos
                    )
                }
            </Row>
        </Container>
    )
}

function Paso2({infoCar, onSelectedPasos}){

    return(
        <Container>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <Container>
                        <p className="text-primary-color">{infoCar?.make} {infoCar?.model} {infoCar?.year}</p>
                        <div style={{height: '30vh'}}>
                            <GatsbyImage
                                fluid={{
                                    aspectRatio: 1 / 1,
                                    src: `${infoCar?.image}`,
                                    srcSet: `${infoCar?.image}, ${infoCar?.image}`,
                                    sizes: "quality: 80",
                                }}
                                className="w-100 rounded"
                                imgStyle={{objectFit: 'contain'}}
                                style={{height: '100%'}}
                            />
                        </div>
                    </Container>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <Cotizador dataCar={infoCar} precioCar1={infoCar?.versions[0].price} versionCar1={infoCar?.versions[0].name} idCar1={infoCar?.versions[0].id} onSelectedPasos={onSelectedPasos} calcularModal={true}/>
                </Col>
            </Row>
        </Container>
    )
}

function Paso3({infoCar, infoCotiza, cargando, calcularCotiza, props, showSeguros, setShowSeguros, seguros, seguroSeleccionado, setSeguroSeleccionado, setShowCotizador, showCotizador, pagoMensual, setCargando}){
    console.log("InfoCotiza: ", infoCotiza);
    let objectCar = {
        make: infoCar.make,
        model: infoCar.model,
        year: infoCar.year,
        fullauto: `${infoCar.make} ${infoCar.model} ${infoCar.year}`
    }

    return(
        <Container>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <div style={{height: '20vh'}} className="pb-2">
                        <GatsbyImage
                            fluid={{
                                aspectRatio: 1 / 1,
                                src: `${infoCar?.image}`,
                                srcSet: `${infoCar?.image}, ${infoCar?.image}`,
                                sizes: "quality: 80",
                            }}
                            alt={ infoCar?.make + ` ` + infoCar?.model + ` ` + infoCar?.year + ` $` + infoCar?.price + ` - Agencia Chevrolet - Crédito de auto`}
                            className="w-100 rounded"
                            imgStyle={{objectFit: 'contain'}}
                            style={{height: '100%'}}
                        />
                    </div>
                    <div style={{color: 'white'}}>
                        <Container>
                            <TablaDetallesCotizador 
                                infoCar={infoCar} 
                                infoCotiza={infoCotiza} 
                                cargando={cargando} 
                                seguroSeleccionado={seguroSeleccionado} 
                                setShowSeguros={setShowSeguros}
                                pagoMensual={pagoMensual}
                                setShowCotizador={setShowCotizador}
                            />
                        </Container>
                    </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <div style={{color: 'white'}}>
                        <h5 className="text-white">Contactar Asesor</h5>
                        <hr />
                        <FormularioVentaSalesforce 
                            props={props} infoCar={objectCar} pagina="cotizador-estrena-en-tres-pasos" btnTitulo={"Contactar asesor"} pagoMensual={pagoMensual}
                        >
                            <input hidden id="00Nf400000UBhZt" name="00Nf400000UBhZt" value="Nuevos" />

                            <input hidden id="00Nf400000UBhZh" name="00Nf400000UBhZh"  value={infoCotiza?.plazo}/>
                            <input hidden id="00N8a00000FPBY3" name="00N8a00000FPBY3"  value={infoCar?.sf_id} /> 
                            <input hidden id="00Nf400000UBhZj" name="00Nf400000UBhZj"  value={pagoMensual?.monthly_payment ? pagoMensual?.monthly_payment : null}/>
                            <input hidden id="00Nf400000UBhZB" name="00Nf400000UBhZB"  value={infoCotiza?.porcentaje ? infoCotiza?.porcentaje : null}/>

                            <input hidden id="version" name="version" value={infoCotiza?.version}  />
                            <input hidden value={infoCotiza?.version} name="00Nf400000UBha2" id="00Nf400000UBha2" readOnly={true}  />
                            <input hidden value={infoCotiza?.precio} name="00Nf400000UBha1" id="00Nf400000UBha1" readOnly={true}  />
                        </FormularioVentaSalesforce>
                    </div>
                </Col>
            </Row>
            <ModalSeguros show={showSeguros} onHide={() => setShowSeguros(false)} seguros={seguros} setSeguroSeleccionado={setSeguroSeleccionado} setShowCotizador/>
            <ModalCotiza setCargando={setCargando} show={showCotizador} onHide={() => setShowCotizador(false)} infoCar={infoCar} infoCotiza={infoCotiza} calcularCotiza={calcularCotiza}/>
        </Container>
    )
}